.App {
  padding-left: 6rem;
  display: grid;
  grid-template-columns: 40% 5% 40%;
  grid-template-rows: 1fr;
  height: 100vh;
  align-content: center;
  justify-content: center;
}

img {
  align-self: center;
  justify-self: center;
  max-width: 100%;
  max-height: 80vh;
  opacity: 0.7;
}

.anton {
  animation: spin 2000s infinite linear;
}

.pizza {
  animation: spin 1500s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    
  }
}

span {
  align-self: center;
  justify-self: center;
  font-size: 100px;
  position: relative;
}
